import Store from "./store";

type roleData = {
  admin: boolean;
  ta: boolean;
};

export const roles: Array<string> = Store.namespace("auth").get("roles") ?? [];

export const admin: boolean = roles.includes("admin");
export const ta: boolean = roles.includes("ta");
export const student: boolean = roles.includes("student");

export const Roles = (data: roleData) => {
  const roles: string[] = [];

  if (data.admin) {
    roles.push("admin");
  }
  if (data.ta) {
    roles.push("ta");
  }

  if (roles.length === 0) {
    roles.push("student");
  }

  Store.namespace("auth").set("roles", roles);

  return roles;
};

export default {
  admin,
  ta,
  student,
};
