import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/problem",
    component: () => import("../views/parsons/Problem.vue"),
    meta: { title: "Problem" },
  },
  {
    path: "/",
    component: () => import("../views/parsons/Home.vue"),
    meta: { title: "Home" },
  },
  {
    path: "/all-problems",
    component: () => import("../views/parsons/ProblemsList.vue"),
    meta: { title: "All Problems" },
  },
  {
    path: "/create",
    component: () => import("../views/parsons/Create.vue"),
    meta: { title: "Create" },
  },
  {
    path: "/forbidden",
    component: () => import("../views/errors/Forbidden.vue"),
    meta: { title: "Forbidden" },
  },
  {
    path: "/auth/callback",
    name: "Logging in...",
    component: () => import("../views/AuthCallback.vue"),
  },
  {
    path: "*",
    component: () => import("../views/errors/NotFound.vue"),
    meta: { title: "Not Found" },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = to.meta?.title
      ? to.meta.title + " - FEH Parsons"
      : "FEH Parsons";
  });
});

export default router;
