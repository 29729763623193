
import { Component, Vue } from "vue-property-decorator";
import { reauthenticate, signOut, TOKENS } from "./shared/auth";
import roles from "./shared/roles";

@Component({
  methods: {
    signOut,
  },
})
export default class App extends Vue {
  visible = false;

  get admin() {
    return roles.admin;
  }

  mounted() {
    if (!TOKENS.LOGGEDIN && window.location.pathname.indexOf("/auth") !== 0) {
      reauthenticate(this.$router.currentRoute.path);
    }
  }
}
